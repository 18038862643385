
export const subscriptionContent = {
  title: {
    eng: "Subscriptions",
    esp: "Suscripciones"
  },
  cards: [
    {
      title:{
        eng: "Power",
        esp: "Mercado Eléctrico"
      },
      subtitle:{
        eng: "Monthly report",
        esp: "Reporte mensual"
      },
      text:{
        eng: `<ul>
                <li>
                  1. Relevant news. 
                </li>
                <li>
                  2. Important events in the market.  
                </li>
                <li>
                  3. Regulatory and public policy alerts. 
                </li>
                <li>
                  4. Commercial opportunities.
                </li>
                <li>
                  5. Quantitative analysis of the market and the country.
                </li>
              </ul>`,

        esp: `<ul>
                <li>
                  1. Noticias relevantes del sector. 
                </li>
                <li>
                  2. Eventos de coyuntura en el mercado. 
                </li>
                <li>
                  3. Análisis de políticas públicas y cambios regulatorios. 
                </li>
                <li>
                  4. Oportunidades comerciales.
                </li>
                <li>
                  5. Indicadores diversos del sector eléctrico y macroeconómicos.
                </li>
              </ul>`
      },
      button: {
        eng: "I want to know more",
        esp: "Quiero saber más "
      }
    },
    {
      title:{
        eng: "Natural <br/>gas",
        esp: "Gas <br/> Natural"
      },
      subtitle:{
        eng: "Monthly report",
        esp: "Reporte mensual"
      },
      text:{
        eng: `<ul>
                <li>
                  1. Natural gas flows.
                </li>
                <li>
                  2. Regulatory alerts. 
                </li>
                <li>
                  3. Oportunities analysis: RFP or public processes from state-owned and private companies, and information on new projects from feasibility analysis to commissioning. 
                </li>
                <li>
                  4. Relevant news in Mexico, North America, and worldwide.
                </li>
              </ul>`,

        esp: `<ul>
                <li>
                  1. Análisis de flujos de gas natural. 
                </li>
                <li>
                  2. Alertas regulatorias. 
                </li>
                <li>
                  3. Análisis de Oportunidades: licitaciones o RFP de empresas públicas o privadas, nuevos proyectos en desarrollo, etc. 
                </li>
                <li>
                  4. Noticias relevantes. de eventos de México, Norteamérica y el mundo, enfocados en el Mercado Mexicano de Gas Natural.
                </li>
              </ul>`
      },
      button: {
        eng: "I want to know more",
        esp: "Quiero saber más "
      }
    },
    {
      title:{
        eng: "Long-Term power",
        esp: "Sector eléctrico de largo plazo"
      },
      subtitle:{
        eng: "Quarterly long-term analysis:",
        esp: "Análisis de mercado eléctrico para los siguientes 60 meses: "
      },
      text:{
        eng: `<ul>
                <li>
                  1. Power market analysis for the following 60 months.
                </li>
                <li>
                  2. Emulating CENACE operations with security constrained economic dispatch, operating rules, and must-run/out-of-merit.
                </li>
                <li>
                  3. Generator expansion incorporating the CENACE interconnection queue, CRE permits, and PRODESEN, along with market intelligence related to PPAs and financing. 
                </li>
                <li>
                  4. Load forecast by node, incorporating the latest economic activity and growth forecasts, electrification of transportation, and distributed generation.
                </li>
                <li>
                  5. Detailed and accurate data.
                </li>
              </ul>`,
        esp: "De forma trimestral, podrás contar con un análisis nacional de los precios y condiciones operativas de todo el Sistema Eléctrico Nacional, nodo a nodo para los siguientes sesenta meses. Con esta información, podrás tomar decisiones estratégicas en tus puntos de generación y consumo."
      },
      button: {
        eng: "I want to know more",
        esp: "Quiero saber más "
      }
    }
  ],
  subscriptions:{
    title:{
      eng: "IN OUR SUBSCRIPTIOP PRODUCTS, WE GO BEYOND",
      esp: "CARACTERÍSTICAS DE NUESTROS PRODUCTOS DE SUSCRIPCIÓN"
    },
    list:[
      {
        eng: "See the forest and the trees",
        esp: "Vemos el bosque y también los árboles"
      },
      { 
        eng: "Industry view of experienced and educated energy economists",
        esp: "Visión de la industria por economistas energéticos experimentados"
      },
      {
        eng: "Identifying both risks and opportunities for our clients",
        esp:"Identificamos oportunidades y riesgos",
      },
      {
        eng: "Ongoing communication with subscribers",
        esp:"Perspectivas enriquecidas, visualizadas"
      },
      {
        eng: "Refreshed periodically to ensure the latest market developments are included",
        esp:"Actualizaciones mensuales para garantizar que se incorporen los últimos sucesos del mercado"
      }
    ]
  }
}