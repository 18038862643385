import "./ImagesCarousel.css";
import carouselImages from "../../assets/carousel-images.png"

const ImagesCarousel = () => {
  return(
    <>
      <div className="images-carousel-container">
        <img 
          className="images-carousel"
          src={carouselImages} 
          alt="Presentation Images" 
        />
      </div>
    </>
  )
}

export default ImagesCarousel;