import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import AboutCard from "../AboutCard/AboutCard";
import "./AboutCardsContainer.css"

const AboutCardsContainer = ({cards}) => {
  const {language} = useContext(AppContext)
  return(
    <>
      <div className="a-cards-container">
        {
          cards &&
          cards.map((item,i) => 
            <AboutCard 
              orientation={item.orientation}
              title={item.title[language]}
              text={item.text[language]}
              image={item.image}
              background={item.background}
              key={i}
            />  
          )
        }
      </div>
    </>
  )
}

export default AboutCardsContainer;