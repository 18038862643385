import "./ConsultancyCard.css"

const ConsultancyCard = ({title,text,image}) => {
  return(
    <>
      <div className="c-card-container">
        <div className="c-card-title"> 
          <h1> 
            {title}
          </h1>
        </div>
        <div className="c-card-image"> 
          <img src={image} alt={title}/>
          <div className="c-card-content"> 
            <p dangerouslySetInnerHTML={{__html: text}}/>
          </div>
        </div>
      </div>
    </>
  )
}

export default ConsultancyCard;