
export const modalContent = {
  title: {
    eng: "Contact form",
    esp: "Formulario de contacto"
  },
  firstName: {
    eng: "Name",
    esp: "Nombre"
  },
  lastName: {
    eng: "Last Name",
    esp: "Apellido"
  },
  phone: {
    eng: "Phone",
    esp: "Teléfono"
  },
  email: {
    eng: "Email",
    esp: "Email Corporativo"
  },
  reason: {
    eng: "Why Elevation iDEAS",
    esp: "¿Por qué te interesa Elevation iDEAS?"
  },
  subscription :{
    eng: "I am interested to know more about",
    esp: "ME INTERESA RECIBIR LA SUSCRIPCIÓN A"
  },
  check1: {
    eng: "Power reports",
    esp: "Reportes del mercado eléctrico"
  },  
  check2: {
    eng: "Natural gas reports",
    esp: "Reportes de gas natural"
  },
  check3: {
    eng: "Long term power reports",
    esp: "Reportes del sector eléctrico de largo plazo"
  },
  button: {
    eng: "Send",
    esp: "Enviar"
  },
  subsMessage: {
    eng: "I am interested to know more about",
    esp: "Me interesa recibir la suscripción a "
  }
}