import React, { useState } from "react";
import "./Header.css";
import headerLogo from "../../assets/header_logo.png"
import {useNavigate} from "react-router-dom";
import { headerContent } from "./content";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";

const Header = () => {
  const [isClosed, setIsClosed] = useState(true)
  const history = useNavigate()
  const {changeLanguage,language} = useContext(AppContext)

  return(
    <>
      <header className="header">
        <div className="header-container">
          <span className="open" onClick={() => setIsClosed(!isClosed)}/>
          <img 
            className="header-logo" 
            src={headerLogo} 
            alt="Elevation ideas logo" 
            onClick={() => history("/")}
          />
          <div className={`header-container-text ${isClosed ? "" : "header-open"}`}>
            <span 
              className="closed" 
              onClick={() => setIsClosed(!isClosed)}>X</span>
            <ul className="header-list">
              <li className="header-list-item list-first-item" onClick={() => history("/")}>
                <p className="list-item-text first-item" onClick={() => setIsClosed(true)}>
                  {headerContent.home[language]}
                </p>
              </li>
              <li className="header-list-item" onClick={() => history("./nosotros")}>
                  <p className="list-item-text" onClick={() => setIsClosed(true)}>
                    {headerContent.about[language]}
                  </p>
              </li>
              <li className="header-list-item" onClick={() => history("./consultoria")}>
                <p className="list-item-text" onClick={() => setIsClosed(true)}>
                  {headerContent.consultancy[language]}
                </p>
              </li>
              <li className="header-list-item" onClick={() => history("./suscripciones")}>
                <p className="list-item-text last-item" onClick={() => setIsClosed(true)}>
                  {headerContent.subscriptions[language]}
                </p>
              </li>
            </ul>
            <div className="header-languages-container">
              <p className="header-language" onClick={() => changeLanguage("esp")}>
                ESP
              </p>
              <p className="header-language" onClick={() => changeLanguage("eng")}>
                ENG
              </p>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header;