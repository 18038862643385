import "./FormModal.css"
import { modalContent } from "./content"
import { useContext } from "react"
import { AppContext } from "../../context/AppContext"

const FormModal = ({isClosed,setIsClosed}) => {

  const {language} = useContext(AppContext)
  
  const handleChange = (e) => {
    if(e.target.value === "on"){
      e.target.value = `${modalContent.subsMessage[language]} ${e.target.name}`
    }
  }
  return(
    <>
      {
        !isClosed &&
        <div className="form-modal-container">
          <div className="form-container">  
            <div className="form-title">
              <span 
                className="close-button" 
                onClick={() => setIsClosed(!isClosed)}
              >X</span> 
              <h2>{modalContent.title[language]}</h2> 
            </div>
            <div className="form-c"> 
              <form action="https://formsubmit.co/marketintel@elevationideas.mx" method="POST"> 
                <input type="text" id="first" name={modalContent.firstName[language]} placeholder={modalContent.firstName[language]} required/>
                <input type="text" name={modalContent.lastName[language]} placeholder={modalContent.lastName[language]} required/>
                <input type="phone" name={modalContent.phone[language]} placeholder={modalContent.phone[language]} required/>
                <input type="email" name={modalContent.email[language]} placeholder={modalContent.email[language]} required/>
                <input type="text" name={modalContent.reason[language]} placeholder={modalContent.reason[language]}/>
                <div className="form-text"> 
                  <p>{modalContent.subscription[language]}:</p>
                </div>
                <div className="form-checkbox-container">
                  <div className="check-container">
                    <input type="checkbox" id="cbox1" name={modalContent.check1[language]} onChange={handleChange} /> 
                    <label htmlFor="cbox1">{modalContent.check1[language]}</label>   
                  </div>
                  <div className="check-container">
                    <input type="checkbox" id="cbox2" name={modalContent.check2[language]} onChange={handleChange}/>
                    <label htmlFor="cbox2">{modalContent.check2[language]}</label>
                  </div>
                  <div className="check-container">
                    <input type="checkbox" id="cbox3" name={modalContent.check3[language]} onChange={handleChange}/> 
                    <label htmlFor="cbox3">{modalContent.check3[language]}</label>
                  </div>
                  <div className="form-button-container">
                    <input type="submit" value={modalContent.button[language]} id="form-button"/>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default FormModal;