import AboutCardsContainer from "../../components/AboutCardsContainer/AboutCardsContainer";
import { aboutContent } from "./content";
import "./About.css";
import ImagesCarousel from "../../components/ImagesCarousel/ImagesCarousel";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";

const About = () => {
  const {language} = useContext(AppContext)

  return( 
    <>
      <ImagesCarousel/>
      <div className="about-container">
        <h1 className="about-title">
          {aboutContent.title[language]}
        </h1>
        <AboutCardsContainer cards={aboutContent.cards}/>
        <div className="mision-container">
          <h2 className="mision-title">
            {aboutContent.mision.title[language]}
          </h2>
          <p className="mision-text">
            {aboutContent.mision.text[language]}
          </p>
          <p className="mision-text">
            {aboutContent.mision.text2[language]}
          </p>
        </div>
      </div>
    </>
  )
}

export default About;