import car1 from "../../assets/car1.png";
import carLogo from "../../assets/car-logo.png"
import consult from "../../assets/consult.png"
import panel from "../../assets/panel.png"

export const homeContent = {
  carousel : [
    {
      image: car1,
      title: {
        image: carLogo,
        eng: "",
        esp: ""
      },
      text: {
        eng: `The most <span style="color:#E9A45D">insightful, thorough</span> and <span style="color:#E9A45D">reliable</span> outlook on the Mexican energy markets.`,
        esp: `La perspectiva más <span style="color:#E9A45D">perspicaz, completa</span> y <span style="color:#E9A45D">confiable</span> sobre los mercados energéticos mexicanos.`
      },
      url: "/nosotros"
    },
    {
      image: consult,
      title: {
        image: "",
        eng: "ADVISORY",
        esp: "CONSULTORÍA"
      },
      text: {
        eng: `Solution and strategic <span style="color:#70A7A3">ideas</span> for your <span style="color:#70A7A3">energy projects</span>: generation, procurement, trading, infrastructure and more.`,
        esp: `Soluciones e <span style="color:#70A7A3">ideas</span> estratégicas para sus <span style="color:#70A7A3">proyectos de energía</span>: generación, consumo, compraventa, infraestructura y más.`
      },
      url: "/consultoria"
    },
    {
      image: panel,
      title: {
        image: "",
        eng: "SUBSCRIPTIONS",
        esp: "SUSCRIPCIONES"
      },
      text: {
        eng: `<span style="color:#606060">Reports and studies of the Electricity and Natural Gas Markets in Mexico.</span>`,
        esp: `<span style="color:#606060">Reportes y estudios periódicos de los mercados mexicanos de</span> electricidad y gas natural.`
      },
      url: "suscripciones"
    }
  ]
}