import "./Consultancy.css";
import headerLogo from "../../assets/header_logo.png"
import ImagesCarousel from "../../components/ImagesCarousel/ImagesCarousel";
import ConsultancyCardsContainer from "../../components/ConsultancyCardsContainer/ConsultancyCardsContainer";
import { consultContent } from "./content";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";

const Consultancy = () => {
  const {language} = useContext(AppContext)
  return(
    <>
      <ImagesCarousel/>
      <div className="consultancy-container">
        <h1 className="consultancy-title">
          {consultContent.title[language]}
        </h1>
        <img
          className="consultancy-logo" 
          src={headerLogo} 
          alt="Elevation ideas logo" 
        />
         <img
          className="consultancy-image" 
          src={consultContent.headerImg[language]} 
          alt="Elevation ideas logo" 
        />
        
        {
          consultContent.containers.map((item,i) => 
            <ConsultancyCardsContainer
              title={item.title[language]}
              cards={item.cards}
              key={i}
            />
          )
        }      
      </div>
    </>
  )
}

export default Consultancy;