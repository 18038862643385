import React from 'react';
import { useState } from 'react';

const initialState = {
  language: "esp"
}

export const AppContext = React.createContext(initialState);

export const ContextProvider = ({children}) => {

  const [state, setState] = useState(initialState)

  const changeLanguage = (language) => {
    setState({...initialState, language: language})
  }
  return(
    <AppContext.Provider value={{...state, changeLanguage}}>
      {children}
    </AppContext.Provider>
  )
}

